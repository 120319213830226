<template>
  <div class="lecture-detail">
    <navbar bg="transparent" :title="title" />
    <div
      v-loading="loading"
      v-if="type === 'detail'"
      class="lecture-detail-inner"
    >
      <div class="title">
        {{ articleDetail.title || "" }}
      </div>
      <div class="content" v-html="articleDetail.content"></div>
      <div class="bottom">
        <div class="date">{{ articleDetail.cdatetime || "2020-08-09" }}</div>
        <div class="view-count">
          浏览
          <span class="red">{{ articleDetail.view_count || "0" }}</span> 次
        </div>
      </div>
    </div>
    <div v-if="type === 'category'">
      <div class="type-tabs">
        <div
          v-for="item in parentCateDetail.sub_cat"
          class="tab"
          :key="item.route"
          :class="{ active: item.id == catId }"
          @click="handleToCate(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="article-list">
        <van-list
          v-model="loading"
          class="list-content"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="fetchList"
        >
          <div
            v-for="item in resultList"
            :key="item.id"
            class="list-item"
            @click="$router.push(`/h5/article?id=${item.id}`)"
          >
            <img class="thumb" :src="item.cover_image" alt="" />
            <div v-if="item.tags" class="right-block">
              <div class="title">
                {{ item.title }} <span class="tags">{{ item.tags }}</span>
              </div>
              <div class="desc">{{ item.description }}</div>
              <!-- <div class="date">{{ item.cdatetime }}</div> -->
            </div>
            <div v-else class="right-block">
              <div class="title">{{ item.title }}</div>
              <div class="date">{{ item.cdatetime }}</div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/h5/Navbar";
// import otherApis from "@/apis/other";
import indexApis from "@/apis/index";
import { List } from "vant";

export default {
  name: "Article",
  components: {
    Navbar,
    "van-list": List
  },
  data() {
    return {
      detail: {},
      page: 1,
      finished: false,
      loading: false,
      catId: "",
      detailId: "",
      cateDetail: {},
      parentCateDetail: {},
      articleDetail: {},
      type: ""
    };
  },
  computed: {
    title() {
      if (!this.type) return "";
      return this.type === "category" ? this.cateDetail.name : "资讯详情";
    }
  },
  created() {
    // this.fetchDetail();
    this.init();
  },
  methods: {
    async init() {
      const { id, cat_id } = this.$route.query;
      this.resultList = [];
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.articleDetail = {};
      // if (search_text) {
      //   this.search_text = search_text;
      //   this.cateDetail = {
      //     name: "搜索结果"
      //   };
      //   this.parentCateDetail = {
      //     name: "搜索结果"
      //   };
      //   this.type = "category";
      //   this.fetchList();
      //   return;
      // }
      if (id) {
        this.detailId = id;
        this.type = "detail";
        await this.fetchDetail();
        this.catId = this.articleDetail.cat_id;
      } else {
        this.type = "category";
        this.catId = cat_id;
        this.fetchList();
      }
      this.fetchCate();
    },

    async fetchCate() {
      try {
        const {
          data: { article_cat }
        } = await indexApis.indexArticleCate({ id: this.catId });
        this.cateDetail = article_cat;

        if (article_cat.parent_id) {
          const {
            data: { article_cat: parentArticleCate }
          } = await indexApis.indexArticleCate({ id: article_cat.parent_id });
          this.parentCateDetail = parentArticleCate;
        } else {
          this.parentCateDetail = article_cat;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchList() {
      try {
        const {
          data: { result, max_page }
        } = await indexApis.indexList({
          page: this.page,
          cat_id: this.catId,
          search_text: this.search_text
        });

        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        this.resultList = [...this.resultList, ...result];
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },

    async fetchDetail() {
      try {
        const {
          data: { article_info }
        } = await indexApis.indexDetail({ id: this.detailId });
        this.articleDetail = article_info;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    },

    handleToCate(id) {
      if (this.type === "category" && id == this.catId) return;
      this.$router.push(`/h5/article?cat_id=${id}`);
      // this.init();
    },

    handleToDetail(id) {
      if (this.type === "detail" && id == this.detailId) return;
      this.$router.push(`/h5/article?id=${id}`);
      // this.init();
    }
  },
  watch: {
    $route(val, oldVal) {
      if (val.query !== oldVal.query) {
        this.init();
      }
    }
  }
};
</script>

<style lang="scss" scope>
.lecture-detail {
  background-color: #fff;
  /* prettier-ignore */
  min-height: calc(100vh - 50PX);
  // box-sizing: border-box;
  // display: flex;
  // justify-content: center;
  // padding: 15px 0;
  // height: calc(100vh - 70px);

  .type-tabs {
    display: flex;
    padding: 0 40px;

    .tab {
      position: relative;
      width: 240px;
      height: 90px;
      background: #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 3px;
      // margin-right: 10px;
      font-size: 36px;
      color: #333333;
      cursor: pointer;

      &:first-of-type {
        border-top-left-radius: 10px;
      }

      &:last-of-type {
        // margin-right: 0;
        border-top-right-radius: 10px;
      }

      &.active {
        opacity: 1;
        color: #ca0a15;
        // background-color: #CA0A15;
      }
    }
  }

  .lecture-detail-inner {
    display: flex;
    flex-flow: column nowrap;
    width: 1045px;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    background: #f7f7f7;
    border-radius: 10px;

    .title {
      font-size: 56px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .content {
      // font-size: 14px;
      line-height: 1.4;
      font-size: 44px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 60px;
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #b5b5b5;
      font-size: 40px;
      margin-top: 48px;

      .red {
        color: #8c4134;
      }
    }
  }
}

.article-list {
  background-color: #fff;
  padding: 0 40px;
  // min-height: 600px;

  // min-height: calc(100% - 90px);

  // height: calc(100% - 46px);
  // padding-top: 20px;

  // .list-header {
  //   height: 40px;
  //   background: #ffffff;
  //   border-radius: 4px;
  //   color: #333333;
  //   font-size: 14px;
  //   display: flex;
  //   justify-content: space-evenly;
  //   align-items: center;
  //   padding: 0 20px;
  // }

  .list-content {
    display: flex;
    flex-flow: column nowrap;
    // min-height: 100%;

    // overflow: auto;
    // height: calc(100% - 60px);
    // flex: none;

    .list-item {
      flex: none;
      display: flex;

      width: 1044px;
      height: 360px;
      background: #f7f7f7;
      // border-radius: 10px;
      margin-top: 40px;
      padding: 40px;
      box-sizing: border-box;

      .thumb {
        height: 280px;
        width: 280px;
        border-radius: 10px;
        background-color: #947ef7;
        flex: none;
        margin-right: 40px;
        object-fit: cover;
        // float: left;
      }

      .right-block {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        flex: 1;
      }

      .title {
        font-size: 48px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .tags {
        font-size: 28px;
        color: #666666;
        float: right;
        line-height: 58px;
        // vertical-align: bottom;
      }

      .desc {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 48px;
        color: #666666;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .date {
        font-size: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b5b5b5;
      }

      &:first-of-type {
        margin-top: 0px;
      }
    }
  }
}
</style>
